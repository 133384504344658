const options = {
	site: "b2consumer-desktop",
	client: "homedepot",
	publishPath: "prod"
};


// Function to read a cookie value by its name.
const readCookie = function( cookieName ) {

	// Encode the cookieName to handle special characters.
	cookieName = encodeURIComponent( cookieName );

	// Prepare the cookies string, replacing spaces with semicolons for easier splitting.
	let cookies = ( ";" + document.cookie ).split( " " ).join( ";" );

	let startIndex = cookies.indexOf( ";" + cookieName + "=" ),
		endIndex = startIndex < 0 ? startIndex : cookies.indexOf( ";", startIndex + 1 );

	return ( startIndex < 0 ) ? "" : decodeURIComponent( cookies.substring( startIndex + 2 + cookieName.length, endIndex < 0 ? cookies.length : endIndex ) )

};

// Function to globally replace all occurrences of a substring in a string.
// const stringReplace = ( source, pattern, replacement ) => source ? source.replace( new RegExp( pattern, 'g' ), replacement ) : source;


// Function to unescape a given string, specifically handling URL-encoded strings.
const reverseEscapeString = function( encodedString ) {
	
	// Return the input directly if it's falsy (e.g., null, undefined, empty string).
	if ( !encodedString ) return encodedString;
	
	// Replace '+' with ' ' (space) if '+' exists in the string, as '+' represents a space in URL encoding.
	encodedString = encodedString.includes( "+" ) ? encodedString.replace( /\+/g, " " ) : encodedString;
	
	try {

		// Attempt to decode the URL-encoded string.
		return decodeURIComponent( encodedString );
	
	} catch ( error ) {
	
		// If decoding fails, log the error (optional) and return the original encoded string.
		// Note: The original recursive call to unescape(encodedString) could lead to infinite recursion if decodeURIComponent always fails.
		// It's safer to return the encodedString or handle the error differently.
		console.warn( "Error decoding URI component:", error );
		return encodedString;
	
	}
};


/**
 * Read Key-Value pair from specified cookie and return value
 * @param {string} cookieName
 * @param {string} keyName
 * @param {string} keySplit
 * @returns {string}
 * example: readCookieKeyValue("s_sess", "s_pv_pName", ";");
 */

const readCookieKeyValue = function( cookieName, keyName, keySplit = ";" ) {
	let key;
	let value = "";
	let pair = [];

	// returns the decoded cookie value or an empty string if the cookie is not found
	let cookieString = readCookie( cookieName );

	let cookieArray = cookieString.split( keySplit );

	for ( let i = 0; i < cookieArray.length; i++ ) {
		pair = cookieArray[ i ].split( '=' );

		key = pair[ 0 ].trim();

		if ( key === keyName ) {
			value = reverseEscapeString( pair[ 1 ] ).trim();
			break;
		}
	}

	return value;

};


/**
 * Determines if the current page is part of the MyCart/Nucleus Cart Overlay Application running in an iFrame
 * @returns {boolean}: true/false
 */
const isOverlay = function() {

	const _PATH = window.location.pathname.toLowerCase(),
		isMyCartOverlay = ( _PATH === '/mycart/overlay' && window.location !== window.parent.location ),
		isNucleusCartOverlay = ( _PATH.indexOf( '/cart/' ) !== -1 && window.location !== window.parent.location );

	return ( isMyCartOverlay || isNucleusCartOverlay );

};


/**
 * Determine what Host the Analytics platform is being provided through
 * @returns {string}: 'GCP' - Google GDN, 'AKA' - Akamai CDN, 'LOC' - Local,  'UNK' - unknown
 */
const getAnalyticsHost = function( scriptSrc = "" ) {

	console.log( LOG_PREFIX + " -- getAnalyticsHost: ", scriptSrc );

	if ( scriptSrc.indexOf( "assets.homedepot-static.com/analytics/" ) > -1 ) {
		return 'GCP'; // Google Cloud Platform
	}
	else if ( scriptSrc.indexOf( "assets.thdstatic.com/analytics/" ) > -1 ) {
		return 'AKA'; // Akamai CDN
	}
	else if ( scriptSrc.indexOf( "localhost" ) > -1 || scriptSrc.indexOf( "127.0.0.1" ) > -1 || scriptSrc.indexOf( "proxyman" ) > -1 ) {
		return 'LOC'; // Local
	}
	else {
		return 'UNK'; // Unknown
	}

}


const getBusinessType = function () {

	if ( options.site.indexOf( 'b2business' ) > -1 ) {
		return 'b2b';
	} else {
		return 'b2c';
	}

};


const getClient = function () {

	return options.client;

};


const getCookieKeySVOCID = function ( cookieName ) {

	let key = ( cookieName === "THD_CUSTOMER" ? "t" : "svocCustomerAccountId" );

	return ( cookieName === "THD_STOREFRONT" ? "SVOCID" : key );
	
};


const getDeviceType = function () {

	let btstrap = getClient();

	if ( isConsumerApp() ) {
		return 'consumer app';
	}
	else if ( btstrap === 'homedepot' ) {
		return 'desktop';
	}
	else if ( btstrap === 'homedepotmobile' ) {
		return 'mobile';
	}
	else {
		return btstrap;
	}
};


const getPublishPath = function () {

	// console.log( LOG_PREFIX + " -- getPublishPath: ", options.publishPath );
	return options.publishPath || "";

};


const isProdDeployment = function() {

	let pubPath = getPublishPath();
	let isProdDomain = location.hostname.indexOf( 'homedepotdev.com' ) === -1;

	if ( ( pubPath === 'prod' || pubPath === 'b2bprod' ) && isProdDomain ) {
		return true;
	}

	return false;

};

const isEprocurementStorefront = function() {

	return document.location.host.indexOf( "storefront." ) !== -1 && document.location.host.indexOf( ".homedepot" ) !== -1;

};

const getStoreNumber = function () {
	
	let storeID = "",
		cookieString = readCookie( "THD_LOCALIZER" ),
		cookieObj = '',
		cookieArray = [];

	// exit early if no cookie found		
	if ( !cookieString ) return storeID;
		
	cookieObj = JSON.parse( decodeURIComponent( cookieString ) );

	if ( cookieObj.THD_LOCSTORE ) {

		cookieArray = cookieObj.THD_LOCSTORE.split("+");
		
		storeID = ( Array.isArray( cookieArray ) && cookieArray.length ? cookieArray[0] : "" );

	}

	return storeID;
	
};

const readJWTPayload = function ( payload = null) {

	if ( payload ) {

		let result = payload.split(".");

		if ( Array.isArray( result ) ) {

			return ( result.length === 3 ? result[1] : result[0] );
		}

	}

	return null;
};

const getSVOCID = function () {

	let cookieInfo = getUserCookie();

	//exit early if no cookie found
	if ( !cookieInfo ) { return ""; }

	if ( cookieInfo.name === "THD_STOREFRONT" ) {
	
		try {

            let jwtObj = JSON.parse(window.atob(readJWTPayload(cookieInfo.value)));

			return ( jwtObj ? jwtObj[getCookieKeySVOCID( cookieInfo.name )] : "" ); 

        } catch ( error ) {

			console.log( LOG_PREFIX + " -- Error with getSVOCID THD_STOREFRONT:", error );

            return "";

        }

	}

	let decodedCookieObject = decodeBase64Cookie( cookieInfo.value, "." );

	return decodedCookieObject[ getCookieKeySVOCID( cookieInfo.name ) ];

};


const getUserCookie = function () {
	
	let customerCookie = readCookie( "THD_CUSTOMER" ),
		userCookie = null,
		storeFrontCookie = null;

	if ( isEprocurementStorefront() ) {

		storeFrontCookie = readCookie( "THD_STOREFRONT" );

		if ( storeFrontCookie ) { return { "name": "THD_STOREFRONT", value: storeFrontCookie } }

	}

	if ( customerCookie ) {			

		return { "name": "THD_CUSTOMER", value: customerCookie } 

	} 

	userCookie = readCookie( "THD_USER" );
	
	if ( userCookie ) { 

		return { "name": "THD_USER", value: userCookie } 
	}

	return null;

};


const isConsumerApp = function () {

	return window.navigator.userAgent.toLowerCase().indexOf( "thdconsumer/" ) !== -1

};


var getMobileAppVersion = function() {

	/*Sample Consumer App User Agent    
	 - "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 THDConsumer/7.34.1.2 (iPhone;iOS 17.5.1) DID:9503DAF9-8E33-5A2E-3D75-0A1EF75C390F"
	 - Would return 7.34.1.2
	*/

	//Initialize our user agent string to lower case.
	let uagent = navigator.userAgent.toLowerCase(),
		version = uagent.split( "thdconsumer/" );

	return ( Array.isArray( version ) && version.length ? version[ 1 ].split( " " )[ 0 ] : "" );

}


const isPEP = function () {

	return window.location.pathname.indexOf("/pep/") !== -1; 

};


const isPIP = function () {

	return window.location.pathname.indexOf("/p/") !== -1; 

};


let isUserPersisted = () => {

	try {
		
		if ( isEprocurementStorefront() ) { return false; } 
		
		const parsedJwt = decodeBase64Cookie( readCookie( "THD_CUSTOMER" ) ) || decodeBase64Cookie( readCookie( "THD_USER_SESSION") );

		// Exit early if no cookie found
		if ( !parsedJwt ) { return false; } 

		const expirationMilliseconds = (parsedJwt.k === 'y') ? 7776000000 : 7200000; // 90d for kmsi, 2hr for non-kmsi
		const jwtTimestamp = parsedJwt.v;
		const currentTimestamp = (new Date()).getTime();
		
		return (currentTimestamp - jwtTimestamp) > expirationMilliseconds;

		
	} catch ( error ) {

		console.log( LOG_PREFIX + " -- Error with isUserPersisted: ", error );
		_aape.errors.push( error );

		return "";	

	}
}

const decodeBase64Cookie = function ( encodedCookieValue, separator = "." ) {

	try {
		
		let encodedSubstring = encodedCookieValue.split( separator )[ 0 ];
		let regexPattern = /"/g;
		let cleanedEncodedSubstring = encodedSubstring.replace( regexPattern, '' );
		let decodedCookieValue = atob( cleanedEncodedSubstring );
		let cookieObject = ( typeof decodedCookieValue === "string" && decodedCookieValue ? JSON.parse( decodedCookieValue ) : "" );

		return cookieObject;

	}
	catch ( error ) {

		console.error( "Base64 Decoding Cookie Error: ", error );
		_aape.errors.push( error );

		return "";

	}

};


/* Adobe Consulting Plugin: getQueryParam v4.0.1  */
/*
The getQueryParam function uses the following arguments:
- qsp (required): A comma delimited list of query string parameters to look for within the URL. It is not case-sensitive.
- de (optional): The delimiter to use if multiple query string parameters match. Defaults to an empty string.
- url (optional): A custom URL, string, or variable to extract the query string parameter values from. Defaults to window.location.

Calling this function returns a value depending on the above arguments and the URL:
- If a matching query string parameter is not found, the function returns an empty string.
- If a matching query string parameter is found, the function returns the query string parameter value.
- If a matching query string parameter is found but the value is empty, the function returns true.
- If multiple matching query string parameters are found, the function returns a string with each parameter value delimited by the string in the de argument.
*/
const getQueryParam = function( a, d, f ) { function n( g, c ) { c = c.split( "?" ).join( "&" ); c = c.split( "#" ).join( "&" ); var e = c.indexOf( "&" ); if ( g && ( -1 < e || c.indexOf( "=" ) > e ) ) { e = c.substring( e + 1 ); e = e.split( "&" ); for ( var h = 0, p = e.length; h < p; h++ ) { var l = e[ h ].split( "=" ), q = l[ 1 ]; if ( l[ 0 ].toLowerCase() === g.toLowerCase() ) return decodeURIComponent( q || !0 ) } } return "" } if ( "-v" === a ) return { plugin: "getQueryParam", version: "4.0.1" }; var b = function() { if ( "undefined" !== typeof window.s_c_il ) for ( var g = 0, c; g < window.s_c_il.length; g++ )if ( c = window.s_c_il[ g ], c._c && "s_c" === c._c ) return c }(); "undefined" !== typeof b && ( b.contextData.getQueryParam = "4.0" ); if ( a ) { d = d || ""; f = ( f || "undefined" !== typeof b && b.pageURL || location.href ) + ""; ( 4 < d.length || -1 < d.indexOf( "=" ) ) && f && 4 > f.length && ( b = d, d = f, f = b ); b = ""; for ( var m = a.split( "," ), r = m.length, k = 0; k < r; k++ )a = n( m[ k ], f ), "string" === typeof a ? ( a = -1 < a.indexOf( "#" ) ? a.substring( 0, a.indexOf( "#" ) ) : a, b += b ? d + a : a ) : b = "" === b ? a : b + ( d + a ); return b } };


const getMobileDevice = function() {
	const deviceIphone = "iphone";
	const deviceIpod = "ipod";
	const deviceIpad = "ipad";
	const engineWebKit = "webkit";
	const deviceAndroid = "android";
	const deviceWinPhone = "windows phone";

	//Initialize our user agent string to lower case.
	const uagent = navigator.userAgent.toLowerCase();

	if ( uagent.search( deviceIphone ) > -1 ) {
		return deviceIphone;
	}
	if ( uagent.search( deviceIpod ) > -1 ) {
		return deviceIpod;
	}
	if ( uagent.search( deviceIpad ) > -1 ) {
		return deviceIpad;
	}
	if ( uagent.search( deviceAndroid ) > -1 ) {
		return deviceAndroid;
	}
	if ( uagent.search( deviceWinPhone ) > -1 ) {
		return deviceWinPhone;
	}
	if ( uagent.search( engineWebKit ) > -1 ) {
		return engineWebKit;
	}
	return "";
};


const isNucleus = function() {

	return ( window.experienceMetadata || Array.from( document.head.childNodes ).find( node => node.nodeType === Node.COMMENT_NODE && node.textContent.trim() ) ) ? true : false ;
};


const stringAppendWithDelimiter = function( original, toAppend, delimiter = "|" ) {
	if ( original ) {
		return original + ` ${ delimiter } ` + toAppend;
	} else {
		return toAppend;
	}
}


export {
	readCookie,
	getAnalyticsHost,
	getBusinessType,
	getDeviceType,
	getMobileAppVersion,
	getMobileDevice,
	getPublishPath,
	getStoreNumber,
	getSVOCID,
	getQueryParam,
	isConsumerApp,
	isNucleus,
	isOverlay,
	isPEP,
	isPIP,
	isProdDeployment,
	isUserPersisted,
	readCookieKeyValue,
	stringAppendWithDelimiter
};
